<template>
    <div class="container">
        <header class="jumbotron">
            <h3>{{this.$route.params.type}} </h3>
        </header>
        <div style="display: inline-block;">
            <Button type="button" label="Dodaj" v-on:click="addForm"/>
            <Button type="button" class="p-button-help" label="Importuj" @click="openImportDialog"/>
            <Dialog header="Import danych z pliku" :closable="false" :draggable="true" :position="'top'" footer="Footer"
                    v-model:visible="displayImportDialog" :contentStyle="{height: 'auto'}">
                <div>
                    <div v-for="format of importFormats" :key="format.key" class="field-radiobutton">
                        <div style="display: inline-flex;">
                            <RadioButton :id="format.key" :name="format.name" :value="format.key"
                                         v-model="importFormat"></RadioButton>
                            <label :for="format.key" :style="{'padding-left': '10px'}">{{format.name}}</label>
                        </div>
                    </div>
                </div>
                <template #footer>
                    <Button label="Zamknij" icon="pi pi-times" @click="closeImportDialog" class="p-button-text"/>
                    <div style="display: inline-block;">
                        <FileUpload
                                class="p-button-help" mode="basic" chooseLabel="Wybierz plik" accept="text/xml, .xls, .xlsx"
                                name="file"
                                :url="uploadUrl" @before-upload="onBeforeUpload"
                                @before-send="onBeforeSend" @upload="onUpload" @error="onError"/>
                    </div>
                </template>
            </Dialog>
        </div>
        <div style="height: calc(100vh - 143px)" v-if="this.selectedUnitTypeKey !== 'education'">
            <DataTable :value="content" :lazy="true" :paginator="true" :rows="lazyParams.limit"
                       :totalRecords="totalRecords" @page="onPage($event)" :loading="loading" responsiveLayout="scroll"
                       class="padding3" :scrollable="true" scrollHeight="flex">
                <template #empty>
                    Nie znalieziono deklaracji
                </template>
                <template #loading>
                    Ładwanie deklaracji. Proszę czekać
                </template>
                <Column field="firstName" header="Imię" v-if="this.selectedUnitTypeKey === 'social'"></Column>
                <Column field="lastName" header="Nazwisko" v-if="this.selectedUnitTypeKey === 'social'"></Column>
                <Column field="place" header="Miejscowość"></Column>
                <Column field="zipCode" header="Kod pocztowy"></Column>
                <Column field="street" header="Ulica"></Column>
                <Column field="buildingNumber" header="Nr domu"></Column>
                <Column field="apartmentNumber" header="Nr mieszkania"></Column>
                <Column field="waterConsumption" header="Zużycie wody [m3]"
                        v-if="this.selectedUnitTypeKey === 'water'"></Column>
                <Column headerStyle="width: 12em" bodyStyle="text-align: center; display: inline-block;">
                    <template #body="slotProps">
                        <Button type="button" class="p-button-sm" label="Edytuj"
                                v-on:click="editForm(slotProps.data.id)"></Button>
                        <Button type="button" class="p-button-danger p-button-sm" label="Usuń"
                                v-on:click="removeForm(slotProps.data.id)"></Button>
                    </template>
                </Column>
            </DataTable>
        </div>
        <div style="height: calc(100vh - 143px)" v-if="this.selectedUnitTypeKey === 'education'">
            <TreeTable :value="content" :lazy="true" :paginator="true" :rows="lazyParams.limit"
                       :totalRecords="totalRecords" @page="onPage($event)" :loading="loading" responsiveLayout="scroll"
                       class="padding3" :scrollable="true" scrollHeight="flex">
                <template #empty>
                    Nie znaleziono danych
                </template>
                <template #loading>
                    Ładwanie deklaracji. Proszę czekać
                </template>
                <Column headerStyle="width: 10em" field="firstName" header="Imię" :sortable="true"
                        :expander="true"></Column>
                <Column field="lastName" header="Nazwisko"></Column>
                <Column field="place" header="Miejscowość"></Column>
                <Column field="zipCode" header="Kod pocztowy"></Column>
                <Column field="street" header="Ulica"></Column>
                <Column field="buildingNumber" header="Nr domu"></Column>
                <Column field="apartmentNumber" header="Nr mieszkania"></Column>
                <Column headerStyle="width: 12em" bodyStyle="text-align: center; display: inline-block;">
                    <template #body="slotProps">
                        <Button type="button" class="p-button-sm" label="Edytuj"
                                v-on:click="editForm(slotProps.node.data.id)"></Button>
                        <Button type="button" class="p-button-danger p-button-sm" label="Usuń"
                                v-on:click="removeForm(slotProps.node.data.id)"></Button>
                    </template>
                </Column>
            </TreeTable>
        </div>

    </div>
</template>

<script>

    import UnitDataService from '../../../services/unitsData.service';
    import TokenService from '../../../services/token.service';
    import {watch, ref} from "vue";

    export default {
        name: "FormList",
        inject: ['unitTypes', 'global'],
        data() {
            return {
                loading: false,
                totalRecords: 0,
                content: "",
                meta: null,
                lazyParams: {
                    limit: 100,
                    page: 1
                },
                selectedUnitTypeKey: '',
                uploadUrl: '',
                displayImportDialog: false,
                importFormat: 'excel',
                importFormats: ref([
                    {name: 'Excel', key: 'excel'},
                ]),
                filter: {
                    quarter: this.global.state.selectedPeroid.quarter,
                    year: this.global.state.selectedPeroid.year
                },
                selectedPeroid: ref(this.global),
            };
        },
        methods: {
            openImportDialog() {
                this.displayImportDialog = true;
            },
            closeImportDialog() {
                this.displayImportDialog = false;
            },
            onBeforeSend(request) {
                request.formData.set('unit', this.$route.params.unitId);
                request.formData.set('quarter', this.global.state.selectedPeroid.quarter);
                request.formData.set('year', this.global.state.selectedPeroid.year);
                request.formData.set('format', this.importFormat);
                request.xhr.setRequestHeader('Authorization', TokenService.getLocalAccessToken());
                return request;
            },
            onUpload() {
                this.$toast.add({
                    severity: 'success',
                    summary: 'Sukces',
                    detail: 'Poprawnie zaimportowano plik',
                    life: 3000
                });
                this.displayImportDialog = false;
                this.getList();
            },
            onError() {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Błąd',
                    detail: 'Nie zaimportowano pliku',
                    life: 3000
                });
            },
            myUploader(event) {
                let uploadedFiles = [];
                for (let file of event.files) {
                    uploadedFiles.push(file);
                }
                let file = uploadedFiles[0];
                const sendData = {
                    file: file,
                    format: event.files[0].type,
                    unit: this.$route.params.unitId,
                    quarter: this.global.state.selectedPeroid.quarter,
                    year: this.global.state.selectedPeroid.year
                };
                UnitDataService.uploadFile(this.selectedUnitTypeKey, sendData).then(
                    () => {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Sukces',
                            detail: 'Poprawnie zaimportowano plik',
                            life: 3000
                        });
                        this.getList();
                    },
                    (error) => {
                        this.content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },
            onPage(event) {
                this.lazyParams.page = event.page + 1;
                this.getList();
            },
            getList: function () {
                this.loading = true;
                UnitDataService.getList(this.$route.params.unitId, this.lazyParams.limit, this.lazyParams.page, this.selectedUnitTypeKey, this.filter).then(
                    (response) => {
                        if (this.selectedUnitTypeKey === 'education') {
                            this.content = this.prepareDataForEducation(response.data.items);
                        } else {
                            this.content = response.data.items;
                        }
                        console.log(this.content);
                        this.meta = response.data.meta;
                        this.totalRecords = this.meta.totalItems;
                        this.loading = false;
                    },
                    (error) => {
                        this.content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },
            prepareDataForEducation: function (content) {
                let guardiansIds = [];
                for (const i in content) {
                    for (const j in content[i].guardians) {
                        guardiansIds.push(content[i].guardians[j].id);
                    }
                }
                let returnContent = [];
                let key = 0;
                let childKey = 0;
                for (const i in content) {
                    if (!guardiansIds.includes(content[i].id)) {
                        returnContent.push({
                            key: key,
                            data: content[i],
                        });
                        if (returnContent[returnContent.length - 1].data.guardians.length > 0) {
                            returnContent[returnContent.length - 1].children = [];
                            childKey = 0;
                            for (let i in returnContent[returnContent.length - 1].data.guardians) {
                                returnContent[returnContent.length - 1].children.push(
                                    {
                                        key: `${key}-${childKey}`,
                                        data: returnContent[returnContent.length - 1].data.guardians[i]
                                    }
                                );
                                childKey++
                            }
                        }
                        key++;
                    }
                }

                return returnContent;
            },
            addForm: function () {
                this.$router.push({path: `${this.global.state.instancePatch}/formularze-danych/${this.$route.params.type}/${this.$route.params.unitId}/dodaj`});
            },
            editForm: function (id) {
                this.$router.push({path: `${this.global.state.instancePatch}/formularze-danych/${this.$route.params.type}/${this.$route.params.unitId}/edytuj/${id}`});
            },
            removeForm: function (id) {
                UnitDataService.removeForm(id).then(
                    () => {
                        this.getList();
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Usunięto',
                            detail: 'Poprawnie usunięto pozycję',
                            life: 3000
                        });
                    },
                    (error) => {
                        this.content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            }
        },
        mounted() {
            this.loading = true;
            watch(this.selectedPeroid, (val) => {
                this.filter = {
                    quarter: val.state.selectedPeroid.quarter,
                    year: val.state.selectedPeroid.year
                };
                this.getList();
            });
            this.selectedUnitTypeKey = this.unitTypes.find(o => o.name === this.$route.params.type).unitTypeKey;
            this.uploadUrl = `${window.location.origin}${this.global.state.instancePatch}/api/units-data/${this.selectedUnitTypeKey}/upload`;
            if (this.selectedUnitTypeKey === 'education') {
                this.importFormats.push({name: 'Librus', key: 'librus'});
            }
            this.getList();
        },
    };
</script>
